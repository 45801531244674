<template>
  <div class="container">
    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Numquam, quisquam. Animi praesentium, excepturi sequi illum doloribus nobis dolore earum dolorum obcaecati ex in beatae soluta quia, ab nesciunt rerum sit.
  </div>
</template>

<script>
export default {
  computed: {
    search() {
      return this.$route.params.search
    }
  }
}
</script>

<style>

</style>